import { Component } from "react"
import { css } from '@emotion/react'
import { Container ,Grid, Typography, Fab} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
//ICONS MATERIAL UI
import PasoOne from '../../../assets/images/footers/paso1.jpg'
import PasoTwo from '../../../assets/images/footers/paso2.jpg'
import PasoThree from '../../../assets/images/footers/img_apoyo.png'
import PasoFour from '../../../assets/images/footers/paso3.jpg'
import PasoFive from '../../../assets/images/footers/paso4.jpg'
import PasoSix from '../../../assets/images/footers/paso5.jpg'
import PasoSeven from '../../../assets/images/footers/paso6.jpg'
import PasoEight from '../../../assets/images/footers/paso7.jpg'

 const number=css`
   position: relative;
   
   font-weight: bold;
    `
     const numIcon=css`
    border-radius: 50%;
    width: 46px;
    height: 46px;
    padding: 8px;

    background: #fff;
    border: 2px solid #fff;
    color: #666;
    text-align: center;

    font: 14px Arial, sans-serif;
    `
    const buttonCss = css`
    @media(min-width: 360px ){
               margin-left: -7px;
            }
     @media(min-width: 468px ){
                margin-left: 10%;
            }
            @media(min-width: 960px ){
                margin-left: 40%;
            }
    
`
const imgStepCss= css`
            width:250px;
            height:150px;
`
const subTitle= css`
            color: rgb(213 28 28);
            font-size: 16px;
`
 
class ComoFuncionaCmp extends Component {    

    render() {
        const {
            titlePrimary=<span>¡Obtener un préstamo con nosotros<br/>es muy sencillo!</span>,
            titleIcon='¿Cómo funciona?',
            txtStepOne=<span><span css={subTitle}><strong>1. Acércate a nosotros y cuéntanos tu necesidad</strong></span><br/>Acude a la sucursal de Apoyo Financiero más cercana a ti, o llámanos al <strong>1-800-891-2778</strong>, o si prefieres <a href="https://apoyo-financiero.com" target="_blank" rel="nofollow noopener noreferrer" style={{color:"rgb(0 131 52)"}}>puedes iniciar la solicitud de tu préstamo aquí.</a></span>,
            txtStepTwo=<span><span css={subTitle}><strong>2. Entrega tus documentos</strong></span><br/>Los requisitos son mínimos, sólo necesitas:<ul><li>Comprobante de Ingresos <strong>(Cheque de tu empresa o Declaraciones de impuestos).</strong></li><li>Identificación Oficial <strong>(California ID - Matrícula Consular - Pasaporte).</strong></li><li> Número de Seguro Social</li><li>Comprobante de Domicilio <strong>(Comprobantes de pago de servicios -luz, gas, teléfono- a tu nombre).</strong></li></ul></span>,
            txtStepthree=<span><span css={subTitle}><strong>3. Sin empeñar</strong></span><br/>No hay necesidad de empeñar tus bienes. Te prestamos desde <strong>$750</strong> a <strong>$15,000</strong> si eres empleado o tienes tu propio negocio.</span>,
            txtStepFour=<span><span css={subTitle}><strong>4. Llena la solicitud</strong></span><br/>Deberás llenar la solicitud correctamente para facilitar la obtención del préstamo.</span>,
            txtStepFive=<span><span css={subTitle}><strong>5. Verificamos los documentos</strong></span><br/>Cotejamos que todos los documentos presentados sean vigentes y verificamos las referencias y empleo.</span>,
            txtStepSix=<span><span css={subTitle}><strong>6. Te damos respuesta rápida</strong></span><br/></span>,
            txtStepSeven=<span><span css={subTitle}><strong>7. Recoge tu cheque en sucursal</strong></span><br/>Recibe tu préstamo ya sea con un cheque a tu nombre o mediante transferencia a tu tarjeta de débito.</span>,
            txtStepEight=<span><span css={subTitle}><strong>8. Realiza tu pago y mantén tu cuenta al corriente.</strong></span><br/>Puedes pagar a la catorcena, a la quincena o al mes, dependiendo de cómo recibas tu ingreso.<br/><br/>*Consulta la sección Opciones de Pago para que elijas la forma más conveniente para realizar tus pagos</span>,
            } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h6" >
                            <span style={{color:'#e66728'}}><strong>{titlePrimary}</strong></span>                        
                        </Typography>
                        <Typography component='h3' variant="h4" >                            
                            <span style={{color:'#698d21'}}><strong>{titleIcon}</strong></span>                        
                        </Typography>
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} >
                    <CardElevation>                    
                    <br/><br/>

                    <Grid container spacing={2}>   

                        <Grid item xs={12} sm={2} >
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>1</span></span> 
                            </Fab>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                        <img src={PasoOne}  css={imgStepCss} alt="img-step1"/>
                        </Grid>
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepOne}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>
                    

                         <Grid item xs={12} sm={2}>
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>2</span></span> 
                            </Fab>
                            </div>
                        </Grid>                        
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepTwo}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                            <img src={PasoTwo}  css={imgStepCss} alt="img-step2"/>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>3</span></span> 
                            </Fab>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                            <img src={PasoThree}  css={imgStepCss} alt="img-step3"/>
                        </Grid>
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepthree}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>4</span></span> 
                            </Fab>
                            </div>
                        </Grid>                        
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepFour}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                            <img src={PasoFour}  css={imgStepCss} alt="img-step4"/>
                        </Grid>
                    
                        <Grid item xs={12} sm={2}>
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>5</span></span> 
                            </Fab>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                            <img src={PasoFive}  css={imgStepCss} alt="img-step5"/>
                        </Grid>
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepFive}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>6</span></span> 
                            </Fab>
                            </div>
                        </Grid>                        
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepSix}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                            <img src={PasoSix}  css={imgStepCss} alt="img-step6"/>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>7</span></span> 
                            </Fab>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                        <img src={PasoSeven}  css={imgStepCss} alt="img-step7"/>
                        </Grid>
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepSeven}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <div css={buttonCss} >
                            <Fab color="secondary" aria-label="add">                            
                                <span css={numIcon}><span css={number}>8</span></span> 
                            </Fab>
                            </div>
                        </Grid>                        
                        <Grid item xs={12} sm={7}>                        
                        <Typography component='subtitle' variant="subtitle" >                             
                                {txtStepEight}           
                                <br/>&nbsp;           
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>                        
                        <img src={PasoEight}  css={imgStepCss} alt="img-step8"/>
                        </Grid>

                        </Grid>
                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

ComoFuncionaCmp.propTypes = {      
    titlePrimary: PropTypes.string,     
    titleIcon: PropTypes.string,
    txtStepOne: PropTypes.string,
    txtStepTwo: PropTypes.string,
    txtStepthree: PropTypes.string,
    txtStepFour: PropTypes.string,
    txtStepFive: PropTypes.string,
    txtStepSix: PropTypes.string,
    txtStepSeven: PropTypes.string,
    txtStepEight: PropTypes.string
               
}

export default withThemeProps(ComoFuncionaCmp, 'VDPNComoFunciona')






